<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 mb-5 pt-2 pb-3">
        <v-col cols="12" lg="2" sm="12" class="mr-5">
          <v-select
            v-model="search"
            :items="search_items"
            label="검색항목"
            item-text="text"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="keyword"
            @keyup.enter="getData()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          sm="12"
          class="mt-2"
          style="white-space: nowrap"
        >
          <v-btn color="primary" @click="getData()" class="mr-2">검색</v-btn>
          <v-btn @click="refresh()">초기화</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :page.sync="page"
          :items-per-page="per_page"
          elevation="0"
          hide-default-footer
          loading-text="로딩중.. 기다려주세요."
          no-data-text="데이터가 없습니다."
          @click:row="detail($event)"
          style="cursor: pointer"
        >
          <template v-slot:[`item.campaigns_length`]="{ item }">
            {{ item.campaigns_length }}개
          </template>
          <template v-slot:[`item.calculate_unit`]="{ item }">
            {{ item.calculate_unit }}회당 {{ item.calculate_result }}원
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td @click.stop.prevent>
              <v-switch
                v-model="item.status"
                :true-value="1"
                :false-value="0"
                color="green darken-1"
                @change="changeState(item.id)"
                inset
              ></v-switch>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td @click.stop.prevent>
              <v-icon
                class="mr-1"
                color="green darken-1"
                @click="editItem(item)"
              >
                mdi-pencil-box-outline
              </v-icon>
            </td>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>언론사 리스트</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                hide-overlay
                transition="dialog-bottom-transition"
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary white--text"
                    rounded
                    style="font-weight: bold"
                  >
                    언론사 등록
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>언론사 {{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-subheader>언론사</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.name"
                        label="언론사명"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>계정정보</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.email"
                        :readonly="editedIndex > -1"
                        label="아이디"
                        hide-details
                      ></v-text-field>
                    </v-row>
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.password"
                        :label="editedIndex > -1 ? '비밀번호 변경' : '비밀번호'"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>담당자</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.contact_name"
                          label="이름"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.contact_email"
                          label="이메일"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.contact_phone"
                          label="연락처"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.contact_url"
                          label="홈페이지"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>탑재유형</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.type"
                          :items="platform_types"
                          label="플랫폼"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="editedItem.inventory"
                          :items="inventory_items"
                          label="인벤토리"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>카테고리</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-combobox
                        v-model="editedItem.category_ids"
                        :items="category_items"
                        item-text="name"
                        item-value="id"
                        label="카테고리"
                        multiple
                        chips
                        hide-details
                      >
                      </v-combobox>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>서비스 타임라인</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.send_date"
                              label="스크립트 전달"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.send_date"
                            @input="menu = false"
                            locale="ko-KR"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.deploy_date"
                              label="스크립트 설치"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.deploy_date"
                            @input="menu2 = false"
                            locale="ko-KR"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.active_date"
                              label="서비스 실행"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.active_date"
                            @input="menu3 = false"
                            locale="ko-KR"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>설문단가</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="설문응답"
                          reverse
                          prefix="회당"
                          v-model="editedItem.calculate_unit"
                          type="number"
                          min="0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          prefix="원"
                          reverse
                          v-model="editedItem.calculate_price"
                          type="number"
                          min="0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1 white--text"
                      @click="save()"
                      >{{ formTitle }}
                    </v-btn>
                    <v-btn color="primary white--text" @click="dialog = false"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-2"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MediaManage",
  data() {
    return {
      keyword: "",
      search: "name",
      search_items: [
        {
          value: "name",
          text: "언론사 명",
        },
        {
          value: "contact_name",
          text: "담당자",
        },
      ],
      inventory_items: ["A", "B", "C", "D"],
      platform_types: ["web", "app"],

      headers: [
        {
          text: "언론사",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "아이디",
          value: "email",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "서비스 실행일",
          value: "active_date",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "담당자",
          value: "contact_name",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "설문 토픽",
          value: "campaigns_length",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "정산 조건",
          value: "calculate_unit",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "ON/OFF",
          value: "status",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      items: [],
      category_items: [],
      loading: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        password: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        contact_url: "",
        category_ids: [],
        send_date: null,
        deploy_date: null,
        active_date: null,
        calculate_unit: 0,
        calculate_price: 0,
        inventory: "",
        type: "",
        status: null,
      },
      defaultItem: {
        name: "",
        email: "",
        password: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        contact_url: "",
        category_ids: [],
        send_date: null,
        deploy_date: null,
        active_date: null,
        calculate_unit: 0,
        calculate_price: 0,
        inventory: "",
        type: "",
        status: null,
      },
      menu: false,
      menu2: false,
      menu3: false,
      page: 1,
      per_page: 10,
      pageCount: 1,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.getData();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "등록" : "수정";
    },
    valid() {
      if (this.editedIndex === -1) {
        if (
          this.editedItem.name &&
          this.editedItem.email &&
          this.editedItem.password
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.editedItem.name) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    this.getData();
    this.getCategory();
  },
  methods: {
    refresh() {
      this.search = this.search_items[0].value;
      this.keyword = "";
      this.getData();
    },
    getCategory() {
      this.loading = true;
      let items = [];
      this.axios.get("api/v1/admin/categories", {}).then((res) => {
        if (res.data.data.categories.length > 0) {
          res.data.data.categories.map((item, index) => {
            items.push({
              no: index + 1,
              id: item.id,
              name: item.name,
            });
          });
        }
        this.category_items = items;
        this.loading = false;
      });
    },
    getData() {
      this.loading = true;
      let items = [];
      let result = 0;
      let date = "";
      let url = `api/v1/admin/medias?page=${this.page}&per_page=${this.per_page}&${this.search}=${this.keyword}`;
      this.axios
        .get(url, {})
        .then((res) => {
          if (res.data.data.medias.length > 0) {
            res.data.data.medias.map((item, index) => {
              result = item.calculate_unit * item.calculate_price;
              date = item.active_date ? item.active_date.substr(0, 10) : null;
              items.push({
                no: index + 1,
                id: item.id,
                name: item.name,
                email: item.user?.email,
                active_date: date,
                contact_name: item.contact_name,
                contact_phone: item.contact_phone,
                calculate_unit: item.calculate_unit ? item.calculate_unit : 0,
                calculate_result: result,
                campaigns_length: item.campaigns ? item.campaigns.length : 0,
                category_ids: item.categories.map((item) => item.id),
                contact_email: item.contact_email,
                contact_url: item.contact_url,
                deploy_date: item.deploy_date,
                calculate_price: item.calculate_price,
                send_date: item.send_date,
                status: item.status,
                inventory: item.inventory,
                type: item.type,
              });
            });
          }
          this.items = items;
          if (
            Number.isInteger(res.data.data.totalCount / this.per_page) === false
          ) {
            this.pageCount =
              Math.floor(res.data.data.totalCount / this.per_page) + 1;
          } else {
            this.pageCount = res.data.data.totalCount / this.per_page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(item) {
      this.$router.push(`/medias/detail/` + item.id);
    },
    changeState(id) {
      const item = this.items.filter((item) => item.id === id)[0];
      let status = item.status === 1 ? "활성화" : "종료";
      if (confirm(`해당 언론사를 ${status}하시겠습니까?`)) {
        const data = {
          name: item.name,
          contact_name: item.contact_name,
          contact_phone: item.contact_phone,
          contact_email: item.contact_email,
          contact_url: item.contact_url,
          category_ids: item.category_ids,
          send_date: item.send_date,
          deploy_date: item.deploy_date,
          active_date: item.active_date,
          calculate_unit: item.calculate_unit,
          calculate_price: item.calculate_price,
          status: item.status,
        };
        this.axios
          .put("api/v1/admin/medias/" + id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `해당 언론사가 ${status}되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      } else {
        this.getData();
      }
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        const data = {
          name: this.editedItem.name,
          contact_name: this.editedItem.contact_name,
          contact_phone: this.editedItem.contact_phone,
          contact_email: this.editedItem.contact_email,
          contact_url: this.editedItem.contact_url,
          category_ids: this.editedItem.category_ids.map((item) => item.id),
          send_date: this.editedItem.send_date,
          deploy_date: this.editedItem.deploy_date,
          active_date: this.editedItem.active_date,
          calculate_unit: this.editedItem.calculate_unit,
          calculate_price: this.editedItem.calculate_price,
          inventory: this.editedItem.inventory,
          type: this.editedItem.type,
          status: this.editedItem.status,
        };
        if (this.editedItem.password) {
          data["password"] = this.editedItem.password;
        }
        this.axios
          .put("api/v1/admin/medias/" + this.editedItem.id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `언론사가 수정되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      } else {
        this.items.push(this.editedItem);
        const data = {
          name: this.editedItem.name,
          email: this.editedItem.email,
          password: this.editedItem.password,
          contact_name: this.editedItem.contact_name,
          contact_phone: this.editedItem.contact_phone,
          contact_email: this.editedItem.contact_email,
          contact_url: this.editedItem.contact_url,
          category_ids: this.editedItem.category_ids.map((item) => item.id),
          send_date: this.editedItem.send_date,
          deploy_date: this.editedItem.deploy_date,
          active_date: this.editedItem.active_date,
          calculate_unit: this.editedItem.calculate_unit,
          calculate_price: this.editedItem.calculate_price,
          inventory: this.editedItem.inventory,
          type: this.editedItem.type,
        };
        this.axios
          .post("api/v1/admin/medias", data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `언론사가 등록되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      }
      this.getData();
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      let category_ids = this.category_items.filter((el) => {
        for (let i = 0; item.category_ids.length > i; i++) {
          if (item.category_ids[i] === el.id) {
            return el;
          }
        }
      });
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.category_ids = category_ids;
      this.dialog = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$Swal
        .fire({
          text: "삭제 하시겠습니까?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          confirmButtonColor: "#1D72DF",
          denyButtonColor: "#DC2B30",
          width: "400px",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .delete("api/v1/admin/medias/" + this.editedItem.id)
              .then(() => {
                this.getData();
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (result.isDenied) {
            this.closeDelete();
          }
        });
    },
  },
};
</script>

<style scoped></style>
